import { Suspense, lazy } from 'react';
// components
import LoadingScreen from '../components/loading-screen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// DASHBOARD: GENERAL
export const HomePage = Loadable(lazy(() => import('pages/dashboard/HomePage')));
export const NbaPage = Loadable(lazy(() => import('pages/dashboard/NbaPage')));
export const CricketPage = Loadable(lazy(() => import('pages/dashboard/CricketPage')));
export const MatchStreamPage = Loadable(lazy(() => import('pages/dashboard/MatchStreamPage')));
export const NbaMatchStreamPage = Loadable(
  lazy(() => import('pages/dashboard/NbaMatchStreamPage'))
);
export const CricketMatchStreamPage = Loadable(
  lazy(() => import('pages/dashboard/CricketMatchStreamPage'))
);
export const Contact = Loadable(lazy(() => import('pages/ContactPage')));
export const AboutPage = Loadable(lazy(() => import('pages/AboutPage')));
export const TermsAndConditionsPage = Loadable(lazy(() => import('pages/TermsAndConditionsPage')));
export const PrivacyPolicyPage = Loadable(lazy(() => import('pages/PrivacyPolicyPage')));
export const BlogsPage = Loadable(lazy(() => import('pages/NewsPage')));
export const BlogPostPage = Loadable(lazy(() => import('pages/BlogPostPage')));

// MAIN
export const Page404 = Loadable(lazy(() => import('pages/Page404')));
