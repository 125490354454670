import { Navigate, useRoutes } from 'react-router-dom';
import { PATH_AFTER_LOGIN } from '../config-global';
import DashboardLayout from '../layouts/dashboard';
import {
  AboutPage,
  BlogPostPage,
  BlogsPage,
  Contact,
  HomePage,
  MatchStreamPage,
  Page404,
  PrivacyPolicyPage,
  TermsAndConditionsPage,
  NbaPage,
  NbaMatchStreamPage,
  CricketPage,
  CricketMatchStreamPage,
} from './elements';

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <Navigate to={PATH_AFTER_LOGIN} replace />,
    },
    {
      path: 'matches',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'soccer', element: <HomePage /> },
        { path: 'nba', element: <NbaPage /> },
        { path: 'cricket', element: <CricketPage /> },
        { path: 'soccer/:teamA/:teamB', element: <MatchStreamPage /> },
        { path: 'nba/:teamA/:teamB', element: <NbaMatchStreamPage /> },
        { path: 'cricket/:heading', element: <CricketMatchStreamPage /> },
      ],
    },
    {
      element: <DashboardLayout />,
      children: [
        { path: '404', element: <Page404 /> },
        { path: 'contact-us', element: <Contact /> },
        { path: 'about-us', element: <AboutPage /> },
        { path: 'terms-and-conditions', element: <TermsAndConditionsPage /> },
        { path: 'privacy-policy', element: <PrivacyPolicyPage /> },
        { path: 'blogs', element: <BlogsPage /> },
        { path: 'blogs/:title', element: <BlogPostPage /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
