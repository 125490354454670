import SvgColor from 'components/svg-color';
import { PATH_DASHBOARD, PATH_PAGE } from 'routes/paths';

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  blog: icon('blog'),
  home: icon('home'),
  football: icon('football'),
  nba: icon('nba'),
  cricket: icon('cricket'),
  about_us: icon('about-us'),
  contact_us: icon('contact-us'),
};

const navConfig = [
  {
    items: [
      { title: 'Home', path: PATH_DASHBOARD?.root, icon: ICONS.home },
      // { title: 'Home', path: PATH_PAGE?.blogs, icon: ICONS.home },
      {
        title: 'Football',
        path: PATH_DASHBOARD?.matches?.allMatches,
        icon: ICONS.football,
        children: [{ title: 'football match' }],
      },
      {
        title: 'NBA',
        isNew: true,
        path: PATH_DASHBOARD?.matches?.allNbaMatches,
        icon: ICONS.nba,
        isCapital: true,
        children: [{ title: 'nba match' }],
      },
      {
        title: 'Cricket',
        path: PATH_DASHBOARD?.matches?.allCricketMatches,
        icon: ICONS.cricket,
        children: [{ title: 'cricket match' }],
      },
      { title: 'Blogs', path: PATH_PAGE?.blogs, icon: ICONS.blog },
      { title: 'About Us', path: PATH_PAGE?.about, icon: ICONS.about_us },
      { title: 'Contact Us', path: PATH_PAGE?.contact, icon: ICONS.contact_us },
    ],
  },
];

export default navConfig;
